







































































































































import VueBase from '@/VueBase'
import { Component } from 'vue-property-decorator'

@Component({ name: 'RoleSetting' })
export default class DepartmentList extends VueBase {
  private dialogFormVisible = false
  private currentPage = 1
  private all_total = 0
  private type = 'add'
  private keyword = ''
  private form: any = {
    name: '',
    permission: [],
    state: 0,
  }
  changeUrl(item: any) {
    item.buttons.forEach((element: any) => {
      element.checked = false
      element.disabled = !item.checked
    })

    // console.log('item.buttons', item, this.roleRouteList, item.checked)
    this.roleRouteList = this.roleRouteList.map((obj: any) => {
      if (obj?.parent === item.id) {
        if (!item.checked) {
          obj.checked = false
          obj.disabled = true
        } else {
          obj.disabled = false
          if (item.name === 'setting' && obj.name === 'about') {
            obj.checked = item.checked
            obj.disabled = true
          }
          const arr = item.redirect.split('/')
          if (obj?.path === arr[arr.length - 1]) {
            obj.checked = item.checked
            obj.disabled = true
          }
        }
      }
      return {
        ...obj,
      }
    })
  }
  async enterDialog() {
    const permission: any[] = []
    this.roleRouteList.forEach((item: any) => {
      if (item.checked) {
        const form: any = {
          url_id: item.id,
          button_ids: [],
        }
        item.buttons.forEach((button: any) => {
          if (button.checked) {
            form.button_ids.push(button.id)
          }
        })
        permission.push(form)
      }
    })
    this.form.permission = permission
    if (this.type === 'add') {
      const res = await this.services.role.addRoles(this.form)
      if (res.status === 201) {
        this.$message.success(res.msg)
        this.getRoles()
      } else {
        this.$message.error(res.msg)
      }
    }
    if (this.type === 'edit') {
      const res = await this.services.role.roleUpdate(this.form.id, this.form)
      if (res.status === 201) {
        this.$message.success(res.msg)
        this.getRoles()
      } else {
        this.$message.error(res.msg)
      }
    }
    this.dialogFormVisible = false
  }
  addRole() {
    this.type = 'add'
    this.form.name = ''
    this.form.id = 0
    this.form.state = 0
    this.roleRouteList.forEach((item: any) => {
      item.checked = false
      item.buttons.forEach((b: any) => {
        b.checked = false
        b.disabled = !item.checked
      })
    })
    this.dialogFormVisible = true
  }
  async editRole(id: number) {
    this.type = 'edit'
    this.form.id = id
    await this.getRoleById()
    this.dialogFormVisible = true
  }
  async getRoleById() {
    const res = await this.services.role.role(String(this.form.id))
    this.form.name = res.data.name
    this.form.state = res.data.state
    let buttons: any[] = []
    let urls: any[] = []
    let resButtons: any[] = []
    let resUrls: any[] = []
    this.roleRouteList.forEach((item: any) => {
      urls.push(item)
      buttons.push(...item.buttons)
    })
    res.data.permission.forEach((item: any) => {
      resUrls.push(item)
      resButtons.push(...item.buttons)
    })

    urls.forEach((item: any) => {
      if (resUrls.some((i: any) => i.id === item.id)) {
        item.checked = true
        item.buttons.forEach((b: any) => (b.disabled = false))
      } else {
        item.checked = false
        item.buttons.forEach((b: any) => (b.disabled = true))
      }
    })

    buttons.forEach((item: any) => {
      if (resButtons.some((i: any) => i.id === item.id)) {
        item.checked = true
      } else {
        item.checked = false
      }
    })
  }

  private async deleteRole(id: number) {
    const res = await this.services.role.roleDelete(String(id))
    if (res.status === 201) {
      this.$message.success(res.msg)
      this.getRoles()
    } else {
      this.$message.error(res.msg)
    }
  }
  private roleRouteList: any = []
  private async getRoleRouteList() {
    const res = await this.services.role.roleRouteList()
    if (res.status === 201) {
      res.data &&
        res.data.forEach((item: any) => {
          item.checked = false
          item.buttons &&
            item.buttons.forEach((element: any) => {
              element.checked = false
              element.disabled = !item.checked
            })
        })
      this.roleRouteList = res.data
    } else {
      this.$message.error(res.msg)
    }
  }
  private handleCurrentChange(val: number) {
    this.currentPage = val
    this.getRoles()
  }
  private async getRoles() {
    const res = await this.services.role.getRoles({
      page: this.currentPage,
      page_size: 10,
    })
    if (res.status === 201) {
      this.tableData = res.data
      this.all_total = res.page.all_total
    } else {
      this.$message.error(res.msg)
    }
  }
  private rules = {
    name: [{ required: true, message: '请输入角色名称', trigger: 'blur' }],
  }
  private tableData = []
  created() {
    this.getRoles()
    this.getRoleRouteList()
  }
}
